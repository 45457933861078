
.debrief-page h6 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.debrief-page .domain-mark {
  text-align: center;
  width: 50px;
  height: 30px;
  margin-left: 5px !important;
  padding: 0;
}

.debrief-page .align-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.debrief-page .badge-component {
  display: inline;
}

.debrief-page .domain-title {
  padding: 2px 5px;
  width: calc(100% - 53px);
}

.debrief-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

.debrief-page .padding-sm {
  padding: 0 5px;
}

.debrief-page .modal-ratings {
  text-align: center;  
}

.debrief-page .btn-print {
  padding: 0 10px;
  width: 50px;
}

.debrief-page .modal-ratings .modal-title h5{
  color: white;
  /* background-color: var(--default-back-color) !important; */
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.debrief-page .modal-indicators .modal-title h5 {
  color: white;
  margin: 0 !important;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.debrief-page .modal-indicators .modal-body .new-indicator {
  margin-top: 20px;
}

.debrief-page .modal-indicators .modal-body .indicator {
  display: flex;
}

.debrief-page .modal-indicators .modal-body .indicator .checkbox {
  display: inline-block !important;
  margin-top: 0.1rem !important;
}

.debrief-page .modal-indicators .modal-body .indicator label {
  display: inline-block;
  margin-bottom: .5rem;
  vertical-align: top;
}

.debrief-page .table label {
  margin-bottom: 0 !important;
}

.debrief-page .title-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 1.1em !important;
  justify-content: center;
  align-items: center;
}

.print-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 0.9px !important;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 28px;
  margin-bottom: 20px;
}

.print-row span {
  font-size: 12px !important;
}

.debrief-page .logo-image {
  width: 45px;
  height: 45px;
}

.debrief-page .btn-rating {
  height: 1.375rem !important;
  min-width: 1.375rem !important;
  width: 1.375rem !important;
  font-size: 0.7375rem !important;
  background-color: #999999 !important;
}

.debrief-page .btn-rating-selected {
  background-color: var(--color-domain-3) !important;
}

.debrief-page .invite-teacher {
  position: fixed;
  z-index: 999;
  bottom: 40px;
  width: 100%;
  text-align: center;
}

.debrief-page .form-check .complete-check-sign::before {
  background-color: white !important;
}

@media screen and (min-width: 1201px) {
  .debrief-page .modal-ratings .modal-dialog {
    max-width: 30% !important;
  }

  .debrief-page .modal-indicators .modal-dialog {
    max-width: 35% !important;
  }
}

@media screen and (max-width: 1200px) {
  .debrief-page .modal-ratings .modal-dialog {
    max-width: 60% !important;
  }
  .debrief-page .modal-indicators .modal-dialog {
    max-width: 60% !important;
  }
  .question-card {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  .debrief-page .modal-ratings .modal-dialog {
    max-width: 70% !important;
  }
  .debrief-page .modal-indicators .modal-dialog {
    max-width: 80% !important;
  }
}

.debrief-page .board {
  margin-top: 6.3rem !important;
}

.debrief-page .range-slider__wrap {
  width: 100% !important;
  margin-top: 3px;
}

.debrief-page .rating-container {
  width: 50%;
}

.debrief-page .step {
  height: 18px;
  width: 18px;
  margin: 0 2px;
  background-color: #eee;
  border: none;
  border-radius: 50%;
  display: inline-block;
  font-size: 9px;
  text-align: center;
  padding-top: 4px;
}

.rating-ticks-print {
  position: absolute;
  top: -8px;
  display: flex;
  width: 94% !important;
  left: 16px;
  justify-content: space-between;
}

.debrief-page .rating-ticks {
  position: absolute;
  top: -8px;
  display: flex;
  width: 94% !important;
  left: 16px;
  justify-content: space-between;
}

.debrief-page .component-title {
  width: 50%;
  padding: 10px 0;
}

.debrief-page .table td, .table th {
  border: 0 !important;
}

.table td, .table th {
  border: 0 !important;
}


.debrief-page .rating-desc {
  display: flex;
  position: absolute;
  width: 118% !important;
  left: -40px !important;
  top: 65px !important;
}

.debrief-page .indicator-label {
  width: 45%;
}

.debrief-page .indicator-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.debrief-page .rating-desc span {
  font-size: 11px;
  margin: 5px;
  margin-right: 10px;
  text-transform: none !important;
}

.debrief-page .rating-desc .rating3 {
  width: 22%;
}

.debrief-page .rating-desc .rating2 {
  width: 23%;
}

.debrief-page .rating-desc .rating1 {
  width: 19%;
}

.debrief-page .rating-desc .rating0 {
  width: 13%;
  margin-right: 2px !important;
}

.debrief-page .rating-desc .ratingN {
  width: 10%;
}

.debrief-page .range-slider__wrap .range-slider__tooltip {
  z-index: 1000;
}

.debrief-page .modal-email .modal-title h5{
  color: white;
  margin: 0 !important;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.print-rating {
  height: 18px;
  width: 18px;
  margin: 0px 2px;
  background-color: #eee;
  border: none;
  border-radius: 50%;
  display: inline-block;
  font-size: 9px;
  text-align: center;
  padding-top: 4px;
}

.print-text {
  font-size: 11px;
  /* margin: 5px; */
  margin-right: 10px;
  text-transform: none !important;
}

.print-rating-desc {
  display: flex;
  position: absolute;
  width: 118% !important;
  left: -40px !important;
  top: 65px !important;
  z-index: 100;
}

@media screen and (min-width: 1601px) {
  .debrief-page .modal-email .modal-dialog {
    max-width: 33% !important;
  }
}

@media screen and (max-width: 1600px) {
  .debrief-page .modal-email .modal-dialog {
    max-width: 40% !important;
  }
}

@media screen and (max-width: 1200px) {
  .debrief-page .rating-desc {
    left: -47px !important;
  }

  .debrief-page .modal-email .modal-dialog {
    max-width: 50% !important;
  }

  .debrief-page .rating-desc .rating3 {
    width: 30%;
  }

  .debrief-page .rating-desc .rating2 {
    width: 24%;
  }

  .debrief-page .rating-desc .rating1 {
    width: 16%;
  }

  .debrief-page .rating-desc .rating0 {
    width: 10%;
  }

  .debrief-page .rating-desc .ratingN {
    width: 10%;
  }

  .debrief-page .range-slider__wrap {
    width: 103% !important;
    margin-top: 3px;
  }

}

@media screen and (max-width: 992px) {
  .debrief-page .modal-email .modal-dialog {
    max-width: 90% !important;
  }
}