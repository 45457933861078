
.consolidate-page h6 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.consolidate-page .domain-mark {
  text-align: center;
  width: 30px;
  height: 30px;
  margin-left: 5px !important;
  padding: 0;
  background-color: var(--color-grey-default);
}

.domain-mark-1-selected {
  background-color: var(--color-domain-1) !important;
}

.domain-mark-2-selected {
  background-color: var(--color-domain-2) !important;
}

.domain-mark-3-selected {
  background-color: var(--color-domain-3) !important;
}

.domain-mark-4-selected {
  background-color: var(--color-domain-4) !important;
}

.domain-mark-5-selected {
  background-color: var(--color-domain-5) !important;
}

.domain-mark-1:hover {
  background-color: var(--color-domain-1) !important;
}

.domain-mark-2:hover {
  background-color: var(--color-domain-2) !important;
}

.domain-mark-3:hover {
  background-color: var(--color-domain-3) !important;
}

.domain-mark-4:hover {
  background-color: var(--color-domain-4) !important;
}

.domain-mark-5:hover {
  background-color: var(--color-domain-5) !important;
}

.consolidate-page .align-items {
  display: flex;
  justify-content: space-between;
}

.consolidate-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

.consolidate-page .padding-sm {
  padding: 5px;
}

.consolidate-page .modal-components {
  text-align: center;  
}

.consolidate-page .modal-components .modal-title h5{
  color: white;
  /* background-color: var(--default-back-color) !important; */
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.consolidate-page .table label {
  margin-bottom: 0 !important;
}

.consolidate-page .table .domain {
  width: 250px !important;
}

.consolidate-page .title-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 1.1em !important;
  justify-content: center;
  align-items: center;
}

.print-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 0.9em !important;
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px 28px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.print-row span {
  font-size: 12px !important;
}

.font-new {
  font-size: 12px !important;
}

.print-board {
  margin-top: 6.3rem !important;
}
.padding-sm {
  padding: 5px;
}

h6 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.card-shardow {
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.domain {
  width: 250px !important;
}

.domain-mark {
  text-align: center;
  width: 30px;
  height: 30px;
  margin-left: 5px !important;
  padding: 0px !important;
  background-color: var(--color-grey-default);
}

.align-items {
  display: flex;
  justify-content: space-between;
}

.consolidate-page .btn-print {
  padding: 0 10px;
  width: 50px;
  max-height: 50px;
}

@media screen and (min-width: 1201px) {
  .consolidate-page .modal-components .modal-dialog {
    max-width: 40% !important;
  }
}

@media screen and (max-width: 1200px) {
  .consolidate-page .modal-components .modal-dialog {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 992px) {
  .consolidate-page .modal-components .modal-dialog {
    max-width: 70% !important;
  }
}

.consolidate-page .board {
  margin-top: 6.3rem !important;
}

.consolidate-page .table td, .table th {
  border: 0 !important;
}