.displaySchools {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.school {
  display: flex;
  align-items: center;
  text-align: left;
  color: black;
  font-weight: 600;
  margin: 0px;
}

.count {
  color: black;
  font-weight: 400;
}