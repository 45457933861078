.classroom-page .form-group {
  text-align: left;
}

.classroom-page .form-group label {
  margin-left: 18px !important;
}

.classroom-page .home {
  padding: 70px 0 !important;
}

.classroom-page .modal-special-grade .grade-save:focus{
  transform: scale(1.1);
}

.classroom-page .modal-special-grade{
  text-align: center;  
}

.classroom-page .modal-special-grade .modal-title h5{
  color: white;
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.classroom-page .modal-special-district .grade-save:focus{
  transform: scale(1.1);
}

.classroom-page .modal-special-district .modal-dialog{
  max-width: 100% !important;
  width: 50%;
}

.classroom-page .modal-special-district{
  text-align: center; 
}

.classroom-page .modal-special-district .modal-title h5{
  color: white;
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.page-title, .modal-district{
  color: white;
  margin: 0 !important;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.total-observation {
  display: inline-block !important;
  width: unset !important;
  text-align: center;
}