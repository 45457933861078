.user-control-page .home {
  padding: 100px 0;
}

.user-control-page .page-title, .user-control-page .modal-district{
  color: black;
  margin: 0 !important;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.user-control-page .card-footer, .user-control-page .modal-footer {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.user-control-page .card-footer {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 15px !important;
}

.user-control-page .total-observation {
  display: inline-block !important;
  width: unset !important;
  text-align: center;
}

.user-control-page .title {
  padding-top: 30px !important;
}

.school-name {
  padding: 0px;
  margin: 0px;
  font-weight: 700;
}

.table-card {
  padding: 0 50px;
}

.tr-border-bottom {
  border-bottom: 1px solid #cdcdcd !important;
}

.user-control-page .modal-district .modal-title h5{
  color: white;
  margin: 0 !important;
  padding: 10px 20px 10px 20px;
  text-align: center;
}

.csv-btn {
  font-size: 13px !important;
  border-radius: 30px !important;
  margin-left: 15px !important;
  padding: 5px 10px !important;
}

.user-control-page .modal-district .modal-dialog {
  min-width: 800px !important;
}

.archivedStatusbutton {
  display: flex;
  justify-content: end;
  padding: 0px !important;
}

@media screen and (min-width: 1201px) {
  .user-control-page .modal-district .modal-dialog {
    max-width: 80% !important;
  }
}

@media screen and (max-width: 1200px) {
  .user-control-page .modal-district .modal-dialog {
    max-width: 80% !important;
  }

}

@media screen and (max-width: 992px) {
  .user-control-page .modal-district .modal-dialog {
    max-width: 80% !important;
  }
}

.edit-btn:hover {
  box-shadow: none !important;
}

.modal-confirm {
  margin: 125px auto !important;
}
