.observe-page .badge {
  margin-bottom: 0 !important;
}

.observe-page .form-group,
.receive-funds-page .form-group,
.expense-page .form-group {
  text-align: left;
}

.observe-page .form-group label,
.receive-funds-page .form-group label,
.expense-page .form-group label {
  margin-left: 18px !important;
}

.observe-page .title {
  margin-bottom: 0 !important;
}

.observe-page .actions .now-ui-icons {
  font-size: 20px !important;
  margin-top: 18px !important;
}

.observe-page .actions .vertical-timeline::before {
  background: var(--default-back-color);
}

.observe-page .arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px !important;
}

.observe-page .arrow {
  color: var(--default-back-color);
  font-size: 30px;
}

.observe-page .form-check .form-check-sign::after {
  color: var(--default-back-color) !important;
}

.observe-page .actions .now-ui-icons {
  font-size: 20px !important;
  margin-top: 10px !important;
}

.observe-page .actions .design_image {
  margin-top: 0px !important;
}

.observe-page .modal-action .action-save:focus {
  transform: scale(1.1);
}

.observe-page .modal-question .action-save:focus {
  transform: scale(1.1);
}

.observe-page .thumbnail-intro {
  font-weight: 500;
  font-size: 18;
}

.observe-page .dropzone {
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.observe-page .note {
  position: relative;
  display: inline-block;
}

.observe-page .note .tooltiptext {
  visibility: hidden;
  width: 50px;
  background-color: #666;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 70%;
  left: 70%;
}

.observe-page .note:hover .tooltiptext {
  visibility: visible;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
    color: var(--default-back-color);
    text-align: left;
  }
}

@media only screen and (max-width: 1170px) {

  .observe-page .arrow-container {
    display: none;
  }

}

.observe-page .modal-action {
  text-align: center;
}

.observe-page .modal-action .modal-title h5 {
  color: white;
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.observe-page .modal-question {
  text-align: center;
}

.observe-page .modal-question .modal-title h5 {
  color: white;
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.observe-page .modal-action .notes {
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
  max-height: 500px !important;
  overflow: auto;
  margin-bottom: 5px;
}

.observe-page .modal-question .notes {
  text-align: left;
  margin-left: 18px;
  margin-right: 18px;
  max-height: 500px !important;
  overflow: auto;
  margin-bottom: 5px;
}

.observe-page .title-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 1.1em !important;
  justify-content: center;
  align-items: center;
}

.observe-page .backdrop {
  z-index: 100;
  color: #fff;
}

.observe-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

.observe-page .padding-sm {
  padding: 5px;
}

.observe-page h6 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.observe-page .align-items {
  display: flex;
  justify-content: space-between;
}

.consolidate-page .badge-status {
  display: inline;
}

.observe-page .table h5 {
  margin-bottom: 0 !important;
}

.observe-page .status-mark {
  background-color: var(--default-back-color);
  width: 30px;
  height: 29px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
}

.observe-page .nav-pills.nav-pills-info .nav-item .nav-link.active,
.nav-pills.nav-pills-info .nav-item .nav-link.active:focus,
.nav-pills.nav-pills-info .nav-item .nav-link.active:hover {
  background-color: var(--default-back-color) !important;
}

.observe-page .nav-pills.nav-pills-info .nav-item .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 150px;
  height: 120px;
  justify-content: center;
  padding-top: 40px;
}

.observe-page .nav-pills.nav-pills-info .nav-item .nav-link img {
  width: 25px;
}

.observe-page .btn-step {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.observe-page .color-non-active {
  background-color: #dedede !important;
  color: var(--default-back-color) !important;
  font-weight: 550 !important;
}

/* Toggle Switch */

.action-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  left: -39px;
  transform: rotate(90deg);
  
}
.action-switch .react-switch-bg{
  overflow: hidden;
  background-color: #648292 !important;
  opacity: 0.3;
}
.react-switch-handle{
  background-color: #648292 !important;
}

@media screen and (min-width: 1201px) {
  .observe-page .modal-action .modal-dialog {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 1200px) {
  .observe-page .modal-action .modal-dialog {
    max-width: 60% !important;
  }
}

@media screen and (max-width: 992px) {
  .observe-page .modal-action .modal-dialog {
    max-width: 70% !important;
  }
}

@media screen and (min-width: 1201px) {
  .observe-page .modal-question .modal-dialog {
    top: 30%;
    max-width: 30% !important;
  }
}

@media screen and (max-width: 1200px) {
  .observe-page .modal-question .modal-dialog {
    top: 30%;
    max-width: 45% !important;
  }
}

@media screen and (max-width: 992px) {
  .observe-page .modal-question .modal-dialog {
    top: 30%;
    max-width: 60% !important;
  }
}

.observe-page .board {
  margin-top: 10rem !important;
}