.admin-portfolio-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

 .title-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 1.1em !important;
  justify-content: center;
  align-items: center;
}

.admin-portfolio-page .btn-print {
  padding: 0 10px;
  width: 50px;
}

.admin-portfolio-page .home {
  padding: 100px 0 !important;
}
@media print {
  .home {
    padding: 0px 0 !important;
  }
}

.admin-portfolio-page .card-text {
  padding: 5px 7px
}

.identity:nth-child(2n) {
  background-color: #f2f5f7;
}

.date-picker {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 30px;
}

.date-picker-container {
  width: 180px;
  background-color: white;
  padding: 4px;
}

.date-picker-input {
  width: 172px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #b9abab;
  color: gray
}
 
.date-picker-go {
  background-color: white;
  border: 1px solid gray;
  color: gray;
  width: 100%;
  
}

.complete-status {
  display: flex;
  align-items: center;
  gap: 20px;
}

.complete-status div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.complete-status input[type="checkbox"] {
  height: 20px;
  width: 20px;
}

.complete-status label {
  margin: 0px;
}

#completed {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background-color: #648292;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

#completed:checked {
  background-color: #648292;
  /* border-color: #ccc; */
}

#completed:checked::after {
  content: "\2713";
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

#notCompleted {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  background-color: #648292;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

#notCompleted:checked {
  background-color: #648292;
  /* border-color: #ccc; */
}

#notCompleted:checked::after {
  content: "\2713";
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

@media screen and (max-width: 1200px) {
  .admin-portfolio-page .home {
    padding: 130px 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .admin-portfolio-page .home {
    padding: 180px 0 !important;
  }
}