.login-page .card-login {
  padding-top: 20px !important;
  border-radius: 0.25rem;
  padding-bottom: 0.7rem;
  min-width: 90% !important;
}

.login-page .header-filter {
  position: relative;
}

.login-page .divider-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20vw;
}

.login-page .divider-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 20vw;
}
  
.login-page .brand img{
  width: 160px !important;
}

@media screen and (max-width: 991.98px) {
  .login-page .brand img{
    width: 100px;
  }
}

.login-page .fields-signup {
  margin: auto !important;
}

.login-page .fields-signup .row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.login-page .btn-create-reset {
  display: flex !important;
  justify-content: space-between;
}

.login-page .card-title {
  margin-top: 15px;
  line-height: 1.25em;
  color: black;
  margin-bottom: 20px;
}

.login-page .link-signup:hover, .link-signup:focus {
    text-decoration:none !important;
}

.login-page .page-header {
  background-size: cover;
}

.login-page .register-container {
  margin-top: -80px !important;
}