:root {
  --default-back-color: #648292;
  --color-domain-1: #5bada3;
  --color-domain-2: #158574;
  --color-domain-3: #d6af20;
  --color-domain-4: #cd1f3d;
  --color-domain-5: #136ba5;
  --color-grey-default: #cdcdcd;
}

.color-domain-1 {
  background-color: #5bada3 !important;
}

.color-domain-2 {
  background-color: #158574 !important;
}

.color-domain-3 {
  background-color: #d6af20 !important;
}

.color-domain-4 {
  background-color: #cd1f3d !important;
}

.color-domain-5 {
  background-color: #136ba5 !important;
}

.star-color-1 {
  background-color: rgba(73, 60, 194, 1)
}

.star-color-2 {
  background-color: rgba(73, 60, 194, 0.8)
}

.star-color-3 {
  background-color: rgba(73, 60, 194, 0.6)
}

.star-color-4 {
  background-color: rgba(73, 60, 194, 0.5)
}

.star-color-5 {
  background-color: rgba(73, 60, 194, 0.4)
}

.star-color-6 {
  background-color: rgba(73, 60, 194, 0.3)
}

.star-color-7 {
  background-color: rgba(73, 60, 194, 0.2)
}

.color-grey-default {
  background-color: #cdcdcd;
}

.color-font-1 {
  color: #5bada3 !important;
}

.color-font-2 {
  color: #158574 !important;
}

.color-font-3 {
  color: #d6af20 !important;
}

.color-font-4 {
  color: #cd1f3d !important;
}

.color-font-5 {
  color: #136ba5 !important;
}

.color-font-default {
  color: #158574;
}

.color-pink {
  background-color: #9e0e85 !important;
}

.color-default {
  color: #648292 !important;
}

.color-black {
  color: #333 !important;
}

.grey-back-color {
  background-color: #eee;
}

.default-back-color {
  background-color: #648292 !important;
}

.red-alert-color {
  background-color: #A62E1B !important;
}

.color-darker-grey {
  background-color: #888 !important;
}

.font-crm-medium {
  font-size: 1.2em !important;
}

.font-crm {
  font-size: 1.2em !important;
}

.font-crm-big {
  font-size: 1.4em !important;
}

.font-crm-bold {
  font-weight: 700;
}

.link {
  font-size: 1.2em !important;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

.navbar .navbar-brand img{
  margin-right: 10px !important;
  height: 50px;
}

li.nav-item .shopping_cart {
  padding-left: 5px !important;
}

.navbar .navbar-nav .nav-link:not(.btn) {
  font-size: 12px !important;
}

.navbar .navbar-nav .nav-link img{
  width: 25px;
}

.title {
  padding-top: 70px !important;
}

.justify-content-right {
  justify-content: flex-end;
}

.panel {
  text-align: center;
}

.form-control {
  font-size: 1em !important;
}

.form-control:disabled, .form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

.form-control:focus, .form-control[readonly] {
  background-color: transparent !important;
  opacity: 1;
}

input {
  font-size: 1.2em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label-default {
  font-size: 1.3em !important;
  color: black;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@media only screen and (max-width: 767.98px) {
  h1 { font-size: 30px !important; }
  h2 { font-size: 25px !important; }
  h3 { font-size: 20px !important; }
  h4 { font-size: 18px !important; }
  h5 { font-size: 16px !important; }
}

@media (min-width: 1200px) {
  .container {
      max-width: 1300px !important;
  }
}

.react-select .react-select__control .react-select__value-container--has-value .react-select__single-value {
  color: #2c2c2c !important;
}

.react-select .react-select__control .react-select__value-container, .react-select .react-select__control .react-select__indicators {
  padding: 2px 15px !important;
  font-size: 1.1em !important;
}

.react-select .react-select__control .react-select__placeholder {
  color: #2c2c2c !important;
}

.section-image:after {
  background-color: rgba(10, 103, 121, 0.8) !important;
}

.page-header:before {
  background-color: #648292 !important;
}

@media screen and (max-width: 992px) {
  label {
    font-size: 1em !important;
  }
}

.popover {
  box-shadow: 0px !important;
}

.confirm-dialog {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: var(--default-back-color);
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
  color: #fff;
}

.font-component {
  font-size: 10px !important;
}

.footer .copyright {
  font-size: 14px !important;
}

.footer .copyright a {
  color:#007bff;
}

.home-page .title {
  font-weight: 900;
}

.home {
  padding: 70px 0;
  position: relative;
  background: #FFFFFF;
}

.icon-tools {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width: 360px !important;
  margin: 0 auto !important;
  padding: 30px 0 0 !important;
  text-align: center !important;
  padding: 20px 0px !important;
  transition: transform 0.5s !important;
  color: #2c2c2c;
}

.icon-tools .icon-image {
  background-repeat: no-repeat !important;
  background-size: contain !important;
  height: 80px !important;
  width: 80px !important;
}

.icon-tools .info-title {
  margin-top: 10px !important;
  margin-bottom: 30px !important;
}

.icon-tools:hover {
  transform: scale(1.02) !important;
  -webkit-transform: scale(1.02) !important;
  cursor: pointer !important;
  color: #2c2c2c;
} 

.badge-component {
  display: inline;
  width: 22px !important;
  height: 22px !important;
  padding: 4px 4px !important;
}

.circle-badge {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  padding: 5px 4px;
  color: #fff;
  text-align: center;
  font-size: 13px;
  display: inline-block;
}

.selected-background-color {
  background-color: rgba(222, 222, 222, 0.3);
}

.session-bar {
  position: fixed;
  z-index: 1029;
  top: 86px;
  left: 0;
  right: 0;
}
@media print {
  .session-bar {
    top: 10px;
  }
}

.home {
  padding: 60px 0;
}

@media screen and (max-width: 991.98px) {
  .home {
    padding: 85px 0;
  }
}

@media screen and (max-width: 767.98px) {
  .home {
    padding: 100px 0;
  }
}

.home-page .home {
  padding: 70px 0 !important;
}

.order-mark {
  background-color: var(--default-back-color);
  width: 30px;
  height: 29px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  display: flex;
  color: white;
}

.color-white {
  color: white !important;
}

.color-alert {
  color: #A62E1B !important;
}

.color-rating-1 {
  background-color: #f4f4f4;
}

.color-rating-2 {
  background-color: #ececec;
}

.color-rating-3 {
  background-color: #dfdbdc;
}

.color-rating-4 {
  background-color: #c2c2c2;
}

.color-rating-5 {
  background-color: #a6a6a6;
}

.color-back-white {
  background-color: white;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.input-group-text i {
  width: 20px !important;
}

.Toastify__toast-body {
  font-size: 15px;
}
.Toastify__toast--default {
  background: #fff;
  color: #fff !important;
}
.Toastify__toast--info {
  background: #648292 !important;
  color: #fff !important;
}
.Toastify__toast--success {
  background: #648292 !important;
  color: #fff !important;
}
.Toastify__toast--warning {
  background: #d6af20 !important;
  color: #fff !important;
}
.Toastify__toast--error {
  background: #2c2c2c !important;
  color: #fff !important;
}
.addQuiz{
  font-size: 16px;
}
.addQuiz:hover {
  cursor: pointer;
}