
.admin-page h6 {
  font-weight: 500 !important;
  margin-bottom: 0 !important;
}

.admin-page .domain-mark {
  text-align: center;
  width: 50px;
  height: 30px;
  margin-left: 5px !important;
  padding: 0;
}

.admin-page .align-items {
  display: flex;
  justify-content: space-between;
}

.admin-page .badge-component {
  display: inline;
}

.admin-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

.admin-page .padding-sm {
  padding: 5px;
}

.admin-page .modal-components {
  text-align: center;  
}

.admin-page .modal-components .modal-title h5{
  color: white;
  /* background-color: var(--default-back-color) !important; */
  margin: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.admin-page .actions {
  width: 300px !important;
}

@media screen and (min-width: 1201px) {
  .admin-page .modal-components .modal-dialog {
    max-width: 40% !important;
  }
}

@media screen and (max-width: 1200px) {
  .admin-page .modal-components .modal-dialog {
    max-width: 50% !important;
  }
}

@media screen and (max-width: 992px) {
  .admin-page .modal-components .modal-dialog {
    max-width: 70% !important;
  }
}