
.card {
  border-radius: 15px !important;
}
.portfolio-page .padding-lg {
  padding: 10px 20px 10px 28px;
}

.portfolio-page .title-container {
  margin-right: 0 !important;
  margin-left: 0 !important;
  font-size: 1.1em !important;
  justify-content: center;
  align-items: center;
}

.identity:nth-child(2n) {
  background-color: #f2f5f7;
}

.portfolio-page .home {
  padding: 100px 0 !important;
}

.portfolio-page .table td, .table th {
  border-top: 0 !important;
}

.portfolio-page .btn-print {
  padding: 0 10px;
  width: 50px;
}

.title-container {
  margin-top: 9px;
  border-radius: 10px !important;
}

@media print {
  body {
     -webkit-print-color-adjust: exact;
  }
}

@media screen and (max-width: 1200px) {
  .portfolio-page .home {
    padding: 130px 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .portfolio-page .home {
    padding: 180px 0 !important;
  }
}